:root {
  --primary-white: #FCFDFE;
  --text-color-1: #0E3B49;
  --text-color-2: #079FAC;
  --text-color-3: #08B6C4;
  --form-text-color-1: #6D8892;
  --form-text-color-2: #125163;
  --bg-color: #079FAC66;
  --disable-color: #E4E9EB;
  --error-color: #FF304F;
  --gradient-1: linear-gradient(92.07deg, #079FAC 1.74%, #134F61 364.78%);
  --gradient-2: linear-gradient(180deg, #08B6C4 0%, #134F61 206.68%);
  --gradient-3: linear-gradient(264.09deg, #134F61 2.56%, #08B6C4 267.74%);
  --gradient-4: linear-gradient(283.08deg, #134F61 -27.76%, #08B6C4 188.57%);
  --desctop-title-font-size: 48px;
  --mobile-title-font-size: 36px;
  --duration: 250ms;
  --timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@font-face {
  font-display: swap;

  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/playfair-display-v30-latin-regular.eot');
  src: url('./fonts/playfair-display-v30-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/playfair-display-v30-latin-regular.woff2') format('woff2'),
    url('./fonts/playfair-display-v30-latin-regular.woff') format('woff'),
    url('./fonts/playfair-display-v30-latin-regular.ttf') format('truetype'),
}

@font-face {
  font-display: swap;
  font-family: 'Playfair Display', serif;
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/playfair-display-v30-latin-700.eot');
  src: url('./fonts/playfair-display-v30-latin-700.eot?#iefix') format('embedded-opentype'),
    url('./fonts/playfair-display-v30-latin-700.woff2') format('woff2'),
    url('./fonts/playfair-display-v30-latin-700.woff') format('woff'),
    url('./fonts/playfair-display-v30-latin-700.ttf') format('truetype'),
    url('./fonts/playfair-display-v30-latin-700.svg#PlayfairDisplay') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/open-sans-v34-latin-300.eot');
  src: url('./fonts/open-sans-v34-latin-300.eot?#iefix') format('embedded-opentype'),
    url('./fonts/open-sans-v34-latin-300.woff2') format('woff2'),
    url('./fonts/open-sans-v34-latin-300.woff') format('woff'),
    url('./fonts/open-sans-v34-latin-300.ttf') format('truetype'),
    url('./fonts/open-sans-v34-latin-300.svg#OpenSans') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open-sans-v34-latin-regular.eot');
  src: url('./fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/open-sans-v34-latin-regular.woff2') format('woff2'),
    url('./fonts/open-sans-v34-latin-regular.woff') format('woff'),
    url('./fonts/open-sans-v34-latin-regular.ttf') format('truetype'),
    url('./fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/open-sans-v34-latin-500.eot');
  src: url('./fonts/open-sans-v34-latin-500.eot?#iefix') format('embedded-opentype'),
    url('./fonts/open-sans-v34-latin-500.woff2') format('woff2'),
    url('./fonts/open-sans-v34-latin-500.woff') format('woff'),
    url('./fonts/open-sans-v34-latin-500.ttf') format('truetype'),
    url('./fonts/open-sans-v34-latin-500.svg#OpenSans') format('svg');
}



* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.375;

  margin: 0;
  padding: 0;
  background-color: var(--primary-white);
  scroll-behavior: smooth;
}

ul,
li,
h1,
h2,
h3,
h4,
button {
  padding: 0;
  margin: 0;
}

h2 {
  font-family: 'Playfair Display', serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 1.334;
  color: var(--text-color-1);
}

h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.417;
}

ul {
  list-style: none;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.375;
  color: var(--text-color-1);
  margin: 0;
}

input, textarea {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.375;
  outline: none;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 991px) {
  h2 {
    font-size: 36px;
  }
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 32px;
  }
}