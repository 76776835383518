.consultation {
  padding: 120px 0 0;

  h2 {
    text-align: center;
    margin:  0 0 65px;

    span {
      color: var(--text-color-2);
    }
  }
  .wrapper {
    display: flex;
    max-width: 1070px;
    margin: 0 auto;
  }

  .img_wpapper {
    width: 100%;
  }

    img {
      max-width: 685px;
      width: 100%;
      display: block;
      margin-top: -60px;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 30px;
    max-width: 420px;
    background: var(--gradient-2);
    box-shadow: 2px 2px 20px rgba(8, 179, 193, 0.16), -1px -1px 6px rgba(8, 179, 193, 0.1), inset -1px -1px 16px rgba(6, 137, 147, 0.04), inset 2px 2px 8px rgba(252, 253, 254, 0.16), inset 1px 1px 6px rgba(252, 253, 254, 0.14);
    border-radius: 35px;

    p {
      font-size: 18px;
      width: 305px;
      color: var(--primary-white);
      margin: 0 0 25px;
    }

    label {
      input, textarea {
        width: 100%;
        padding: 15px 25px;
        border: 1px solid transparent;
        border-radius: 100px;
        box-shadow: 2px 2px 8px rgba(18, 84, 102, 0.16), inset 2px 2px 4px rgba(8, 182, 196, 0.1);
        margin: 0 0 25px;
        color: var(--form-text-color-2);

        &::placeholder {
          color: var(--form-text-color-1);
        }

        &:focus:invalid {
          border: 1px solid var(--error-color);

          & + p {
            visibility: visible;
          }

        }

        &:invalid:not(:placeholder-shown) {
          border: 1px solid var(--error-color);
        }
        
      }

      textarea {
        border-radius: 25px;
        outline: none;
        resize: none;
      } 
      
      .error_message {
        font-size: 12px;
        font-weight: 400;
        color: #E0313A;
        margin: -16px 0 5px 25px;
        visibility: hidden;

      }
    }
   

    button {
      font-family: 'Open sans', sans-serif;
      font-weight: 500;
      font-size: 16px;
      position: relative;
      padding: 15px 65px;
      border: none;
      border-radius: 50px;
      background: var(--primary-white);
      color: var(--form-text-color-2);
      box-shadow: 4px 4px 6px rgba(18, 84, 102, 0.26), inset 2px 2px 6px rgba(8, 182, 196, 0.1);
      transition-property: all;
      transition-duration: var(--duration);
      transition-timing-function: var(--timing-function);
      z-index: 1;

      &::after {
        border: none;
      }

      &:hover {
        cursor: pointer;
        color: var(--primary-white);
        background: var(--gradient-1);

        &::after {
          content: '';
          position: absolute;
          top: -2px;
          left: -2px;
          width: 100%;
          height: 100%;
          border: 1px solid var(--primary-white);
          border-radius: 50px;
        }
      }

      &:active {
        color: var(--primary-white);
        background: var(--gradient-3);
        box-shadow: 2px 2px 20px rgba(8, 179, 193, 0.16), -1px -1px 6px rgba(8, 179, 193, 0.1), inset -1px -1px 16px rgba(6, 137, 147, 0.04), inset 2px 2px 8px rgba(252, 253, 254, 0.16), inset 1px 1px 6px rgba(252, 253, 254, 0.14);
      }
    }
  }

     @media screen and (max-width: 991px) {
      
      padding: 80px 0 0;

      h2 {
        margin: 0 0 40px;
      }
      .wrapper {
        display: block;

        .img_wpapper {
            display: none;
        }
        
        form {
            max-width: 100%;

            p {
              width: 100%;
            }
        }
      }
    }
    @media screen and (max-width: 705px) {
      padding: 0;
    
    }
}