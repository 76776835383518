.wrapper {
  position: absolute;
  width: 100%;

  .circle_1 {
    max-width: 1280px;
    width: 100%;
    min-width: 1000px;
    height: 1280px;
    position: absolute;
    top: -295px;
    left: -510px;
    background-image: url('../../images/circle1.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    animation: 2s alternate slidein1 var(--timing-function);
  }

  .circle_2 {
    max-width: 920px;
    width: 100%;
    height: 920px;
    position: absolute;
    top: -380px;
    right: -657px;
    background-image: url('../../images/circle2.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    animation: 2s alternate slidein2 var(--timing-function);
  }

  .circle_3 {
    max-width: 700px;
    width: 100%;
    height: 700px;
    position: absolute;
    top: 510px;
    right: -630px;
    background-image: url('../../images/circle3.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    animation: 2s alternate slidein2 var(--timing-function);
  }

  @media screen and (max-width: 1440px) {
    .circle_3 {
      right: -560px;
    }
  }

  @media screen and (max-width: 1200px) {
    .circle_1 {
      max-width: 1100px;
      top: -160px;
    }

    .circle_2 {
      max-width: 540px;
      top: -310px;
      right: -300px;
    }
  }

  @media screen and (max-width: 991px) {
    .circle_1 {
      min-width: 950px;
      top: -290px;
    }

    .circle_3 {
      max-width: 240px;
      height: 240px;
      top: 455px;
      right: -140px;
    }
  }

  @media screen and (max-width: 705px) {
    .circle_1 {
      top: -346px;
      left: -141px;
    }

    .circle_2,
    .circle_3 {
      display: none;
    }
  }

  @keyframes slidein1 {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes slidein2 {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }

}