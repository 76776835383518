.hero {
  padding: 50px 0;

  .hero_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hero_text {
      font-size: 20px;
      color: var(--text-color-1);
      margin: 0 0 10px;

      &__colored {
        max-width: 425px;
        color: var(--text-color-2);
        margin: 0 0 30px;
      }
    }

    .hero_title {
      font-family: 'Playfair Display', serif;
      font-size: 96px;
      font-weight: 400;
      line-height: 1.3;
      color: var(--text-color-1);
      margin: 0 0 15px;
    }

    .hero_button {
      font-family: 'Open sans', sans-serif;
      font-weight: 500;
      font-size: 16px;
      position: relative;
      color: var(--primary-white);
      padding: 15px 65px;
      border: none;
      border-radius: 50px;
      background: var(--gradient-1);
      box-shadow: 2px 2px 20px rgba(8, 179, 193, 0.16), -1px -1px 6px rgba(8, 179, 193, 0.1), inset -1px -1px 16px rgba(6, 137, 147, 0.04), inset 2px 2px 8px rgba(252, 253, 254, 0.16), inset 1px 1px 6px rgba(252, 253, 254, 0.14);
      transition-property: all;
      transition-duration: var(--duration);
      transition-timing-function: var(--timing-function);
      z-index: 1;

      &::after {
        border: none;
      }

      &:hover {
        cursor: pointer;
        color: var(--text-color-3);
        background: transparent;


        &::after {
          content: '';
          position: absolute;
          top: -2px;
          left: -2px;
          width: 100%;
          height: 100%;
          border: 2px solid var(--text-color-2);
          border-radius: 50px;
        }
      }

      &:active {
        color: var(--primary-white);
        background: var(--gradient-3);
        box-shadow: 2px 2px 20px rgba(8, 179, 193, 0.16), -1px -1px 6px rgba(8, 179, 193, 0.1), inset -1px -1px 16px rgba(6, 137, 147, 0.04), inset 2px 2px 8px rgba(252, 253, 254, 0.16), inset 1px 1px 6px rgba(252, 253, 254, 0.14);
      }
    }

    .hero_img {
      img {
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    @media screen and (max-width: 1200px) {
      .hero_title {
        font-size: 48px;
      }

      .hero_text {
        font-size: 18px;
        max-width: 285px;
        text-align: center;

        &__colored {
          max-width: 300px;
        }
      }

      .hero_img {
        img {
          max-width: 500px;
        }
      }

    }

    @media screen and (max-width: 991px) {
      .hero_img {
        img {
          max-width: 375px;
        }
      }
    }

    @media screen and (max-width: 705px) {
      flex-wrap: wrap;
      justify-content: center;

      .hero_content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .hero_text__colored {
        text-align: center;
      }

      .hero_button {
        width: 100%;
        margin: 0 0 40px;
      }
    }

  }
}