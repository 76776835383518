.features {
  padding: 120px 0;
  background: var(--gradient-3);

  .wrapper {
    position: relative;
    z-index: 2;
    color: var(--primary-white);
  }

  .title {
    text-align: center;
    color: inherit;
    margin: 0 0 65px;
  }

  ul {
    display: flex;
    justify-content: center;

    li {
      width: 190px;
      text-align: center;

      img {
        margin: 0 0 15px;
      }
       p {
        font-size: 18px;
        line-height: 1.45;
        color: inherit;
       }
    }

  }

  .bg {
    width: 1258px;
      position: absolute;
      top: -172px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }

  @media screen and (max-width: 767px) {
      padding: 80px 0;

      .title {
          width: 290px;
          margin: 0 auto 30px;
          line-height: 1;
        }

        ul {
          flex-wrap: wrap;

          li {
            width: 140px;

            &:nth-child(-n+2) {
                margin: 0 0 30px;
              }
              p {
                font-size: 16px;
              }
          }
        }
          .bg {
            top: -140px; 
          }
  }
}