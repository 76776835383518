.prices {
  padding: 0 0 120px;

  .title {
    text-align: center;
    margin: 0 0 60px;
  }
  .cards_list {
    display: flex;
    justify-content: center;

    .card {
      max-width: 376px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding: 30px;
      box-shadow: 2px 2px 20px rgba(8, 179, 193, 0.16), -1px -1px 6px rgba(8, 179, 193, 0.1), inset -1px -1px 16px rgba(6, 137, 147, 0.04), inset 2px 2px 8px rgba(252, 253, 254, 0.16), inset 1px 1px 6px rgba(252, 253, 254, 0.14);
      border-radius: 36px;

      &:not(:last-child) {
        margin-right: 20px;
      }


      h3 {
        max-width: 290px;
        text-align: center;
        color: var(--text-color-2);
        margin: 0 0 15px;
      }

      p {
        text-align: center;
        margin: 0 0 20px;
      }
      .type {
        font-size: 20px;
        line-height: 1.4;
        padding: 0 82px 15px;
        margin: 0 0 30px;
        border-bottom: 1px solid var(--text-color-2);
      }
      .price {
        font-family: 'Playfair Display',serif;
        font-size: 36px;
        font-weight: 700;
        line-height: 1.34;
        color: var(--form-text-color-2);
        margin: 0 0 10px;
      }
      .quantity {
        margin: 0 0 5px;
      }
      .quantity, .duration {
        font-size: 18px;
        line-height: 1.45;
      }

      button {
        font-family: 'Open sans', sans-serif;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        color: var(--primary-white);
        padding: 15px 65px;
        border: none;
        border-radius: 50px;
        background: var(--gradient-1);
        box-shadow: 2px 2px 20px rgba(8, 179, 193, 0.16), -1px -1px 6px rgba(8, 179, 193, 0.1), inset -1px -1px 16px rgba(6, 137, 147, 0.04), inset 2px 2px 8px rgba(252, 253, 254, 0.16), inset 1px 1px 6px rgba(252, 253, 254, 0.14);
        transition-property: all;
        transition-duration: var(--duration);
        transition-timing-function: var(--timing-function);
        z-index: 1;

        &::after {
          border: none;
        }

        &:hover {
          cursor: pointer;
          color: var(--text-color-3);
          background: transparent;


          &::after {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: 100%;
            height: 100%;
            border: 1px solid var(--text-color-2);
            border-radius: 50px;
          }
        }

        &:active {
          color: var(--primary-white);
          background: var(--gradient-3);
          box-shadow: 2px 2px 20px rgba(8, 179, 193, 0.16), -1px -1px 6px rgba(8, 179, 193, 0.1), inset -1px -1px 16px rgba(6, 137, 147, 0.04), inset 2px 2px 8px rgba(252, 253, 254, 0.16), inset 1px 1px 6px rgba(252, 253, 254, 0.14);
        }
      }

      &:nth-child(even) {
        background: var(--gradient-2);
        color: var(--primary-white);

        h3, p {
          color: inherit ;
        }
        .type {
          border-color: inherit;
        }

        button {
          background: var(--primary-white);
          color: var(--form-text-color-2);
          box-shadow: 4px 4px 6px rgba(18, 84, 102, 0.26), inset 2px 2px 6px rgba(8, 182, 196, 0.1);

            &:hover {
                color: var(--primary-white);
                background: var(--gradient-1);
                  
                &::after {
                      border-color: var(--primary-white);
                    }
            }

            &:active {
              color: var(--primary-white);
              background: var(--gradient-3);
              box-shadow: 2px 2px 20px rgba(8, 179, 193, 0.16), -1px -1px 6px rgba(8, 179, 193, 0.1), inset -1px -1px 16px rgba(6, 137, 147, 0.04), inset 2px 2px 8px rgba(252, 253, 254, 0.16), inset 1px 1px 6px rgba(252, 253, 254, 0.14);
            }
        }
      }
    }
  }

     @media screen and (max-width: 991px) {
      padding: 0 0 80px;
      .title {
          margin: 0 0 40px;
        }
  
      .cards_list {
         flex-direction: column;

          .card {
            max-width: 100%;
            padding: 25px;

            &:not(:last-child) {
                margin: 0 0 20px;
              }
            
          }
       }
     }

    @media screen and (max-width: 767px) {
      padding: 0 0 60px;
      .title {
          margin: 0 0 30px;
        }
    }
}